import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetMyTracksRequest } from "../models/Request/GetMyTracksRequest";
import { IndexCollectionRequest } from "../models/Request/IndexCollectionRequest";
import { InitSpotifyAuthRequest } from "../models/Request/InitSpotifyAuthRequest";
import { LoginRequest } from "../models/Request/LoginRequest";
import { GetMyTracksResponse } from "../models/Response/GetMyTracksResponse";
import { IndexCollectionResponse } from "../models/Response/IndexCollectionResponse";
import { InitSpotifyAuthResponse } from "../models/Response/InitSpotifyAuthResponse";
import { LoginResponse } from "../models/Response/LoginResponse";
import { apiBaseUrl } from "./environment";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    prepareHeaders: (headers) => {
      const authToken = localStorage.getItem("token");
      if (authToken) {
        headers.set("Authorization", `Bearer ${authToken}`);
      }
      return headers;
    },
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    initSpotifyAuth: builder.query<
      InitSpotifyAuthResponse,
      InitSpotifyAuthRequest
    >({
      query: (request) => ({
        url: "auth/spotify/init",
        method: "post",
        body: request,
      }),
    }),
    login: builder.query<LoginResponse, LoginRequest>({
      query: (request) => ({
        url: "auth/login",
        method: "post",
        body: request,
      }),
    }),
    indexCollection: builder.query<
      IndexCollectionResponse,
      IndexCollectionRequest
    >({
      query: (request) => ({
        url: "collection/index",
        method: "post",
        body: request,
      }),
    }),
    getMyTracks: builder.query<GetMyTracksResponse, GetMyTracksRequest>({
      query: (request) => ({
        url: "tracks",
        method: "post",
        body: request,
      }),
    }),
  }),
});

export const {
  useLazyInitSpotifyAuthQuery,
  useLazyLoginQuery,
  useLazyIndexCollectionQuery,
  useGetMyTracksQuery,
} = api;
