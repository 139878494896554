import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  initialized: boolean;
  token?: string | null;
}

const initialState: AuthState = {
  initialized: false,
};

export interface AuthAction {
  token: string;
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    init: (state) => {
      const authToken = localStorage.getItem("token");
      if (authToken) {
        state.token = authToken;
      }
      state.initialized = true;
    },
    authSuccess: (state, action: PayloadAction<AuthAction>) => {
      state.token = action.payload.token;
      localStorage.setItem("token", action.payload.token);
    },
    logout: (state) => {
      localStorage.removeItem("token");
      state.token = null;
    },
  },
});

export const { init, authSuccess, logout } = authSlice.actions;

export default authSlice.reducer;
