import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LoginPage from "./pages/Login";
import HomePage from "./pages/Home";
import AuthPage from "./pages/Auth";
import TracksPage from "./pages/Tracks";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { init } from "./redux/slices/authSlice";
import PrivateApp from "./PrivateApp";
import AppPaths from "./utils/AppPaths";

const AppRoutes = () => {
  const { initialized } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(init());
  }, [dispatch]);

  if (!initialized) {
    return null;
  }
  return (
    <Routes>
      <Route
        path="*"
        element={
          <RequireAuth redirectTo={AppPaths.Login}>
            <Routes>
              <Route
                path="/"
                element={<Navigate to={AppPaths.Home} replace />}
              />
              <Route path={AppPaths.Home} element={<HomePage />} />
              <Route path={AppPaths.Tracks} element={<TracksPage />} />
            </Routes>
          </RequireAuth>
        }
      />
      <Route path={AppPaths.Login} element={<LoginPage />} />
      <Route path={AppPaths.Auth} element={<AuthPage />} />
    </Routes>
  );
};

interface RequireAuthProps {
  children: JSX.Element;
  redirectTo: string;
}

const RequireAuth = ({ children, redirectTo }: RequireAuthProps) => {
  const auth = useAppSelector((state) => state.auth);
  const location = useLocation();

  if (!auth.token) {
    return <Navigate to={redirectTo} state={{ from: location }} replace />;
  }

  return <PrivateApp>{children}</PrivateApp>;
};

export default AppRoutes;
