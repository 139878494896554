import { useNavigate } from "react-router-dom";
import AppPaths from "../utils/AppPaths";

const useAppNavigate = () => {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate(AppPaths.Home);
  };

  const navigateToTracks = () => {
    navigate(AppPaths.Tracks);
  };

  return {
    navigateToHome,
    navigateToTracks,
  };
};

export default useAppNavigate;
