import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Track } from "../../models/Response/partial/Track";

interface Props {
  tracks: Track[];
}

const TrackList = ({ tracks }: Props) => {
  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      {tracks.map((track) => (
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={track.title}
            secondary={
              <>
                {track.artists.map((artist) => (
                  <span style={{ marginRight: 10 }}>{artist.name}</span>
                ))}
              </>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default TrackList;
