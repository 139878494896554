import { createTheme } from "@mui/material/styles";
import LinkBehavior from "../components/LinkBehavior";
import { LinkProps } from "@mui/material/Link";

const HELIUM_BLUE = "#3399ff";
//const HELIUM_PINK = "#ff40dd";

const palette = {
  secondary: {
    main: HELIUM_BLUE,
  },
};

const theme = createTheme({
  palette: palette,
  typography: {
    h1: {
      fontSize: 30,
    },
    h2: {
      fontSize: 22,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
});

export default theme;
