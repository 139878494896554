import CircularProgress from "@mui/material/CircularProgress";

const WorkInProgress = (): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: 20,
      }}
    >
      <CircularProgress size={36} />
    </div>
  );
};

export default WorkInProgress;
