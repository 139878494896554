interface Props {
  size?: SpacerSize;
}

export enum SpacerSize {
  Small = 0.5,
  Default = 1,
  Large = 2,
  xLarge = 3,
}

const Spacer = ({ size }: Props): JSX.Element => {
  if (!size) size = SpacerSize.Default;

  return <div style={{ width: `${size}em`, height: `${size}em` }}></div>;
};

export default Spacer;
