import { BrowserRouter } from "react-router-dom";
//import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import AppRoutes from "./AppRoutes";
import theme from "./utils/theme";
import { store } from "./redux/store";
import { Provider } from "react-redux";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AppRoutes />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
