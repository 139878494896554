import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Spacer, { SpacerSize } from "../../components/Spacer";
import { useLazyInitSpotifyAuthQuery } from "../../utils/api";
import { hostBaseUrl } from "../../utils/environment";
import LoadingButton from "@mui/lab/LoadingButton";

const LoginPage = () => {
  const [init, initResult] = useLazyInitSpotifyAuthQuery();

  const handleLogin = async () => {
    try {
      const res = await init({
        redirectUri: `${hostBaseUrl}/auth`,
      }).unwrap();
      window.location.replace(res.uri);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ paddingTop: 10 }}>
      <Box p={2} textAlign="center">
        <Typography variant="h1">Helium.fm</Typography>
        <Spacer size={SpacerSize.xLarge} />
        <LoadingButton
          variant="contained"
          size="large"
          sx={{ width: "100%" }}
          type="submit"
          onClick={handleLogin}
          loading={initResult.isFetching}
        >
          Login with Spotify
        </LoadingButton>
        {initResult.isError && (
          <>
            <Spacer />
            <Typography>Sorry. Something didn't work out.</Typography>
          </>
        )}
      </Box>
    </Container>
  );
};

export default LoginPage;
