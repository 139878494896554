import MuiBottomNavigation from "@mui/material/BottomNavigation";
import MuiBottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import HomeIcon from "@mui/icons-material/Home";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import theme from "../../utils/theme";
import AppPaths from "../../utils/AppPaths";
import LinkBehavior from "../LinkBehavior";
import useAppNavigate from "../../hooks/useAppNavigate";

const BottomNavigationAction = styled(MuiBottomNavigationAction)(`
  &.Mui-selected {
    color: ${theme.palette.secondary.main};
  }
`);

const BottomNavigation = () => {
  const [value, setValue] = React.useState(0);
  const { navigateToHome, navigateToTracks } = useAppNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location) {
      if (location.pathname.indexOf(AppPaths.Home) === 0) {
        setValue(0);
      } else if (location.pathname.indexOf(AppPaths.Tracks) === 0) {
        setValue(1);
      } else if (location.pathname.indexOf("/anotherpage") === 0) {
        setValue(2);
      } else {
        setValue(-1);
      }
    }
  }, [location]);

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        display: { md: "none" },
      }}
      elevation={3}
    >
      <MuiBottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          // todo: Is this needed?
          //setValue(newValue);
        }}
      >
        <BottomNavigationAction
          onClick={navigateToHome}
          label="Home"
          LinkComponent={LinkBehavior}
          icon={<HomeIcon />}
        />
        <BottomNavigationAction
          onClick={navigateToTracks}
          label="Tracks"
          LinkComponent={LinkBehavior}
          icon={<MusicNoteIcon />}
        />
      </MuiBottomNavigation>
    </Paper>
  );
};

export default BottomNavigation;
