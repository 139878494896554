import TrackList from "../../components/TrackList";
import WorkInProgress from "../../components/WorkInProgress";
import { useGetMyTracksQuery } from "../../utils/api";

const Tracks = () => {
  const { data: tracks, isFetching } = useGetMyTracksQuery({});

  return (
    <div className="App">
      <h1>Tracks</h1>
      {isFetching && <WorkInProgress />}
      {!isFetching && tracks && <TrackList tracks={tracks.tracks} />}
    </div>
  );
};

export default Tracks;
