import Container from "@mui/material/Container";
import BottomNavigation from "./components/BottomNavigation";
import ResponsiveAppBar from "./components/ResponsiveAppBar";

interface Props {
  children?: React.ReactNode;
}

const PrivateApp = ({ children }: Props) => {
  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth="xl">{children}</Container>
      <BottomNavigation />
    </>
  );
};

export default PrivateApp;
