import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

interface Props {
  title: string;
  path: string;
}

const MainMenuItem = ({ title, path }: Props) => {
  const navigate = useNavigate();
  const active = activeRoute(path);
  return (
    <Button
      variant={active ? "contained" : "text"}
      color="secondary"
      sx={{ my: 2, color: active ? "white" : "black", display: "block" }}
      onClick={() => navigate(path)}
    >
      {title}
    </Button>
  );
};

const activeRoute = (path: string) => {
  return window.location.pathname.indexOf(path) === 0 ? true : false;
};

export default MainMenuItem;
