import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLazyLoginQuery } from "../../utils/api";
import { useAppDispatch } from "../../redux/hooks";
import { authSuccess } from "../../redux/slices/authSlice";
import { hostBaseUrl } from "../../utils/environment";
import AppPaths from "../../utils/AppPaths";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import WorkInProgress from "../../components/WorkInProgress";
import Spacer from "../../components/Spacer";

const Auth = () => {
  const [login, loginResult] = useLazyLoginQuery();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (searchParams && searchParams.get("code")) {
        try {
          const loginResponse = await login({
            spotifyCode: searchParams.get("code") || "",
            redirectUri: `${hostBaseUrl}${AppPaths.Auth}`,
          }).unwrap();
          dispatch(
            authSuccess({
              token: loginResponse.accessToken,
            })
          );
          navigate(AppPaths.Home, { replace: true });
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, [dispatch, login, navigate, searchParams]);

  return (
    <Container maxWidth="sm" sx={{ paddingTop: 10 }}>
      <Box p={2} textAlign="center">
        <Typography variant="h1">Hang on...</Typography>
        {loginResult.isFetching && <WorkInProgress />}
        {loginResult.isError && (
          <>
            <Spacer />
            <Typography>Sorry. Something didn't work out.</Typography>
          </>
        )}
      </Box>
    </Container>
  );
};

export default Auth;
