import { useLazyIndexCollectionQuery } from "../../utils/api";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Spacer from "../../components/Spacer";

const Home = () => {
  const [indexCollection, indexResult] = useLazyIndexCollectionQuery();

  const handleIndexClick = async () => {
    try {
      const indexResponse = await indexCollection({}).unwrap();
      console.log("Index response: ", indexResponse);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Home</h1>
        <LoadingButton
          variant="contained"
          size="large"
          sx={{ width: "100%" }}
          type="submit"
          onClick={handleIndexClick}
          loading={indexResult.isFetching}
        >
          Index my music
        </LoadingButton>
        {indexResult.isSuccess && (
          <>
            <Spacer />
            <Typography>
              Your music is now being indexed in the background.
            </Typography>
            <Typography>Please check the Tracks page periodically.</Typography>
          </>
        )}
      </header>
    </div>
  );
};

export default Home;
